import http from '@/api/request'

// 在线考试(未登录)
// 在线考试分页
export function onlineExamPage(data = {}) {
  return http().post('/resource/api/paper/config/page', data)
}

// 在线考试模拟试卷详情 -- 未登录
export function onlineExamView(id) {
  return http().get(`/resource/api/paper/config/view?id=${id}`)
}

// 在线考试用户信息提交 -- 未登录
export function onlineExamUserInfoSave(data) {
  return http().post(`/resource/api/paper/record/save/exam/user`, data)
}

// 在线考试开始考试 -- 未登录
export function onlineExamStartExam(data = {}) {
  return http().post('/resource/api/paper/record/start/exam', data)
}

// 在线考试继续考试 -- 未登录
export function onlineExamRecordContinue(data = {}) {
  return http().post('/resource/api/paper/record/continue', data)
}

// 在线考试考试完成 -- 未登录
export function onlineExamFinishExam(data = {}) {
  return http().post('/resource/api/paper/record/finish/exam', data)
}

// 在线考试保存试题答案 -- 未登录
export function onlineExamAnswerSave(data = {}) {
  return http().post('/resource/api/paper/record/answer/save', data)
}

// 在线考试校验用户是否处于考试中 -- 未登录
export function onlineExamCheckStatus(data) {
  return http().get('/resource/api/paper/record/check/status', { params: data })
}

// 切屏次数更新 -- 未登录
export function onlineExamPaperRecordUpdate(data = {}) {
  return http().post('/resource/api/paper/record/update', data)
}

// 学生考试交卷提醒
export function userExamSubmitSave(data) {
  return http().post('/base/auth/shop/msg/save', data)
}

// 检查试卷上架状态
export function paperCheckStatus(id) {
  return http().get(`/resource/auth/paper/check/status?id=${id}`)
}

// 校验用户是否处于考试中
export function paperRecordCheck(data = {}) {
  return http().post('/resource/auth/paper/record/check', data)
}

// 校验用户是否处于考试中 -- 模拟考试
export function paperRecordCheckStatus(id) {
  return http().get(`/resource/auth/paper/record/check/status?paperId=${id}`)
}

// 用户开始考试 -- 未登录(入学测试)
export function startExamApi(data = {}) {
  return http().post('/resource/api/shop/entrance/test/start/exam', data)
}

// 用户开始考试
export function startExam(data = {}) {
  return http().post('/resource/auth/paper/record/start/exam', data)
}

// 查看考试信息
export function paperExamInfo(data = {}) {
  return http().post('/resource/auth/paper/exam/info', data)
}

// 继续考试
export function paperRecordContinue(data = {}) {
  return http().post('/resource/auth/paper/record/continue', data)
}

// 保存试题答案 -- 未登录(入学测试)
export function paperAnswerSaveApi(data = {}) {
  return http().post('/resource/api/shop/entrance/test/answer/save', data)
}

// 保存试题答案
export function paperAnswerSave(data = {}) {
  return http().post('/resource/auth/paper/record/answer/save', data)
}

// 切屏次数更新
export function paperRecordUpdate(data = {}) {
  return http().post('/resource/auth/paper/record/update', data)
}

// 考试完成 -- 未登录(入学测试)
export function paperFinishExamApi(data = {}) {
  return http().post('/resource/api/shop/entrance/test/finish/exam', data)
}

// 考试完成
export function paperFinishExam(data = {}) {
  return http().post('/resource/auth/paper/record/finish/exam', data)
}

export function paperExamView(data = {}) {
  return http().post('/resource/auth/paper/record/answer/report', data)
}

// 查看考试解析 -- 未登录(入学测试)
export function userAnswerParseApi(data = {}) {
  return http().post('/resource/api/shop/entrance/test/user/answer/parse', data)
}

// 查看考试解析
export function userAnswerParse(data = {}) {
  return http().post('/resource/auth/paper/record/user/answer/parse', data)
}

// 用户考试记录分页
export function paperRecordPage(data = {}) {
  return http().post('/resource/auth/paper/record/page', data)
}

// 用户收藏添加
export function collectionUserSave(data = {}) {
  return http().post('/scm/auth/collection/user/save', data)
}

// 用户收藏删除
export function collectionUserDelete(data = {}) {
  return http().delete('/scm/auth/collection/user/delete', { data })
}

// 用户开始练习
export function startPractice(data = {}) {
  return http().post('/resource/auth/practice/record/start/practice', data)
}

// 用户开始练习
export function endPractice(data = {}) {
  return http().post('/resource/auth/practice/record/end/practice', data)
}

// 用户练习答案添加
export function practiceAnswerSave(data = {}) {
  return http().post('/resource/auth/practice/answer/save', data)
}

// 查看练习错题
export function wrongQuestionList(data = {}) {
  return http().get(`/resource/auth/practice/record/view?id=${data.practiceRecordId}`)
}

// 预览资源
export function materialPreView(data) {
  return http().post('/resource/api/material/preview', data)
}

/**
 * 课程考试排行
 * @param data
 * @returns {*}
 */
export function examRecordRank(data) {
  return http().post('/resource/auth/paper/record/exam/ranking', data)
}

/**
 * (旧)模拟试卷分页 -- 已登录
 * @param data
 * @returns {*}
 */
export function authPaperConfigOldPage(data) {
  return http().post('/resource/auth/paper/config/page', data)
}

/**
 * (新)模拟试卷分页 -- 未登录
 * @param data
 * @returns {*}
 */
export function paperConfigPage(data) {
  return http().post('/resource/api/paper/config/page', data)
}

/**
 * (新)模拟试卷分页 -- 已登录
 * @param data
 * @returns {*}
 */
export function authPaperConfigPage(data) {
  return http().post('/resource/auth/paper/config/new/page', data)
}

// 查询模拟试卷详情 -- 未登录
export function paperConfigView(id) {
  return http().get(`/resource/api/paper/config/view?id=${id}`)
}

// 查询模拟试卷详情 -- 已登录
export function paperConfigAuthView(id) {
  return http().get(`/resource/auth/paper/config/view?id=${id}`)
}

/**
 * (旧)练习分页 -- 已登录
 * @param data
 * @returns {*}
 */
export function practiceAuthOldPage(data) {
  return http().post('/resource/auth/practice/page', data)
}

/**
 * (新)练习分页 -- 已登录
 * @param data
 * @returns {*}
 */
export function practiceAuthPage(data) {
  return http().post('/resource/auth/practice/new/page', data)
}

/**
 * (新)练习分页 -- 未登录
 * @param data
 * @returns {*}
 */
export function practicePage(data) {
  return http().post('/resource/api/practice/new/page', data)
}

// 查询练习详情 -- 未登录
export function practiceDataView(id) {
  return http().get(`/resource/api/practice/view?id=${id}`)
}

// 查询练习详情 -- 已登录
export function practiceDataAuthView(id) {
  return http().get(`/resource/auth/practice/data/view?id=${id}`)
}

/**
 * 检查用户练习权限
 * @param data
 * @returns {*}
 */
export function practiceCheckStatus(data) {
  return http().post('/resource/auth/practice/check/status', data)
}

/**
 * 用户开始-继续练习
 * @param data
 * @returns {*}
 */
export function practiceTitleStartPathFinding(data) {
  return http().post('/resource/auth/practice/title/start/path/finding', data)
}

/**
 *
 * 用户结束练习
 * @param data
 * @returns {*}
 */
export function practiceTitleEndPathFinding(data) {
  return http().post('/resource/auth/practice/title/end/path/finding', data)
}

/**
 * 保存每日一练-章节练习答案
 * @param data
 * @returns {*}
 */
export function practiceTitleAnswerSave(data) {
  return http().post('/resource/auth/practice/title/practice/answer/save', data)
}

/**
 * 保存闯关练习答案
 * @param data
 * @returns {*}
 */
export function practiceTitleSave(data) {
  return http().post('/resource/auth/practice/title/answer/save', data)
}

/**
 * 查看练习结果
 * @param data
 * @returns {*}
 */
export function practiceRecordInfo(data) {
  return http().post('/resource/auth/practice/record/info', data)
}

/**
 * 移除小节或关卡的练习记录
 * @param data
 * @returns {*}
 */
export function practiceRemoveTitleRecord(data) {
  return http().post('/resource/auth/practice/title/remove/practice/record', data)
}

/**
 * 闯关详情
 * @param data
 * @returns {*}
 */
export function practiceAuthView(id) {
  return http().get('/resource/auth/practice/view?id=' + id)
}
/**
 * 闯关详情 -- 未登录
 * @param data
 * @returns {*}
 */
export function practiceView(id) {
  return http().get('/resource/api/practice/page?id=' + id)
}

/**
 * 开始错题练习
 * @param data
 * @returns {*}
 */
export function errorQuestionStartErrorPractice(data) {
  return http().post('/scm/auth/error/question/start/error/practice', data)
}

/**
 * 保存错题练习答案
 * @param data
 * @returns {*}
 */
export function errorQuestionSaveAnswer(data) {
  return http().post('/scm/auth/error/question/save/answer', data)
}

/**
 * 结束错题-收藏练习
 * @param data
 * @returns {*}
 */
export function errorQuestionEndErrorPractice(data) {
  return http().post('/scm/auth/error/question/end/error/practice', data)
}

/**
 * 查看用户做答记录(错题-收藏题)
 * @param data
 * @returns {*}
 */
export function errorQuestionUserAnswerInfo(data) {
  return http().post('/scm/auth/error/question/user/answer/info', data)
}

/**
 * 清除上次错题-收藏练习记录
 * @param data
 * @returns {*}
 */
export function errorQuestionMoveLastErrorQuestionRecord(data) {
  return http().post('/scm/auth/error/question/move/last/error/question/record', data)
}

/**
 * 收藏试题 或 移除(错题-收藏)试题
 * @param data
 * @returns {*}
 */
export function userOperateQuestion(data) {
  return http().post('/scm/auth/error/question/operation/error/question', data)
}

/**
 * 用户错题信息汇总
 * @param data
 * @returns {*}
 */
export function errorQuestionCount(data) {
  return http().post('/scm/auth/error/question/count', data)
}

/**
 * 用户错题批量删除
 * @param data
 * @returns {*}
 */
export function errorQuestionBatchDelete(data) {
  return http().post('/scm/auth/error/question/batch/delete', data)
}

/**
 * 用户修改错题设置
 * @param data
 * @returns {*}
 */
export function updateErrorQuestionSet(data) {
  return http().put('/base/auth/shop/user/update/error/question/set', data)
}

/**
 * 闯关排行榜
 * @param data
 * @returns {*}
 */
export function practiceEnterList(data) {
  return http().post('/resource/api/practice/enter/list', data)
}

/**
 * 当前用户闯关排名
 * @param data
 * @returns {*}
 */
export function userRankView(data) {
  return http().get('/resource/auth/practice/rank/view', { params: data })
}

/**
 * 练习重做（整体的练习记录会被移除）
 * @param data
 * @returns {*}
 */
export function practiceRedo(data) {
  return http().get('/resource/auth/practice/record/practice/redo', { params: data })
}

/**
 * 用户课程考试
 * @param data
 * @returns {*}
 */
export function courseRecordPage(data) {
  return http().post('/resource/auth/paper/record/course/page', data)
}

/**
 * 用户班级考试
 * @param data
 * @returns {*}
 */
export function classroomRecordPage(data) {
  return http().post('/resource/auth/paper/record/classroom/page', data)
}

/**
 * 试题 上传文档
 * @param data
 * @returns {*}
 */
export function uploadAuthDoc(data) {
  return http().post('/base/auth/upload/doc', data)
}